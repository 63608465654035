import React, { useState } from 'react';
import { Button, View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';


import { Colors } from '../utils/colors';
import { Answerer } from '../components/Answerer';
import { TechMexBrandName } from '../components/TechMexBrandName';
import { TechMexCanvas } from '../components/TechMexCanvas';

export default function WelcomeScreen({ navigation }) {
  
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  /*return (
    <View style={styles.container}>
      <View style={styles.logoContainer}>
        <Text style={styles.subtitle}>TechMex</Text>
        <Answerer setQuestion={setQuestion} setAnswer={setAnswer} />
      </View>
      <View style={styles.buttonContainer}>
        <Button title="Acceso" onPress={() => navigation.navigate('Login')} />
        {<AppButton
          title="Registro"
          color="secondary"
          onPress={() => navigation.navigate('Register')}
        />}
      </View>
      <View style={styles.buttonContainer}>
      <Button color="#ff5733" title="Acceso" onPress={() => navigation.navigate('Login')} />
    </View>
    </View>
  );*/

  return( <View style={styles.container}>   
    <TouchableOpacity
            style={
                styles.floatingButton
                }
            onPress={()=>{navigation.toggleDrawer();}}
            >
            <Icon
                name="bars"
                size={25}
                style={styles.buttonIcon}
            />
        </TouchableOpacity>    
    <TechMexCanvas style={styles.techMexCanvas} /> 
    <TechMexBrandName  />   
    <Answerer setQuestion={setQuestion} setAnswer={setAnswer}  />                        
    
  </View>    )
}

const styles = StyleSheet.create({
  container: { 
    height: '100%', 
    padding: 5,
    backgroundColor:'#222222',
    flex:1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  floatingButton:{
    position: 'absolute',
    top: 30,
    right: 30,
    zIndex:1
  },
  buttonIcon: {        
    color: '#FFF',        
  },
  techMexCanvas: {      
    flex:4
  },
  logoContainer: {
    position: 'absolute',
    top: 60,
    alignItems: 'center'
  },
  logo: {
    width: 250,
    height: 250
  },
  subtitle: {
    fontSize: 24,
    fontWeight: '600',
    paddingVertical: 20,
    color: Colors.black
  },
  buttonContainer: {
    padding: 20,
    paddingBottom: 60,
    width: '100%'
  },
  
});


/*


export default function WelcomeScreen({ navigation }) {

    return(
        <View style={styles.container}>
            <StatusBar style='dark-content' />
            <Text style={styles.title}>Explora + Encuentra + Renta</Text>
        </View>
      );
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#dadada',
      paddingTop: 50,
      paddingHorizontal: 12
    },
    title: {
      fontSize: 24,
      fontWeight: '600',
      color: '#222222',
      alignSelf: 'center',
      paddingBottom: 24
    }
  });

  */