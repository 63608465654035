import React, { useState, useEffect, useContext } from 'react';
import { TouchableOpacity, StyleSheet, TextInput, Text, View, Button } from 'react-native';
import {AppContext} from '../context/AppContextProvider'; 
import Icon from 'react-native-vector-icons/FontAwesome';
import {Pensando} from'./Pensando'

export const Answerer = ({setQuestion, setAnswer}) => {
    const [recognizer, setRecognizer] = useState(null);          
    const [isListening, setIsListening] = useState(false);      
    const [isThinking, setIsThinking] = useState(false);  
    // @ts-ignore  
    const {generate} = useContext(AppContext)

    useEffect(()=>{
        const voices = speechSynthesis.getVoices();
        const voiceSynth = window.speechSynthesis;
        const recognition = new webkitSpeechRecognition();
        recognition.lang= 'es-ES';
        recognition.continuous=false;
        recognition.onresult= async event => {            
            if(event.results){
                const question = event.results[0][0].transcript;
                setIsThinking(true);
                const answer = await generate(question);
                const phrase = new SpeechSynthesisUtterance(answer);
                phrase.voice = voices[0];
                phrase.pitch = 1;
                voiceSynth.speak(phrase); 
                setQuestion(event.results[0][0].transcript);
                setAnswer(answer);
                setIsThinking(false);
            }
        }
        setRecognizer(recognition);
    },[])
    
    const toggleListening = () => {
        isListening ? recognizer.stop() : recognizer.start()         
        setIsListening(!isListening);
      };
        
  return (
    <View style={styles.container}>
        <Text style={styles.welcomeText}>¿Que quieres saber?</Text>
        {isThinking?<Pensando/>:<TouchableOpacity
            style={[
                styles.button,
                { backgroundColor: isListening ? '#FFA500' : '#FF5733' },
            ]}
            onPress={toggleListening}
            >
            <Icon
                name="microphone"
                size={24}
                style={styles.buttonIcon}
            />
            <Text style={styles.buttonText}>
                {isListening ? 'Detener' : 'Escuchar'}
            </Text>
        </TouchableOpacity>}
    </View>
  )
}

const styles = StyleSheet.create({
    container:{
        flex:1,
        alignItems:'center'
    },
    welcomeText:{
        color:'white', 
        margin:30,
        fontSize:20
    },
    button: {
      width: 80,
      height: 80,
      borderRadius: 30,
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 20,
      color:'#FFF'
    },
    buttonIcon: {        
        color: '#FFF',        
    },
    buttonText: {
      fontSize: 14,
      color: '#FFF',
      marginTop: 5,
    },
  });