import { initializeApp } from 'firebase/app';
import Constants from 'expo-constants';

import { onAuthStateChanged, getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

     
// Initialize Firebase
const firebaseConfig = {
  apiKey: Constants?.manifest?.extra?.firebaseApiKey,
  authDomain: Constants?.manifest?.extra?.firebaseAuthDomain,
  dataBaseURL: Constants?.manifest?.extra?.firebaseDataBaseURL,
  projectId: Constants?.manifest?.extra?.firebaseProjectId,
  storageBucket: Constants?.manifest?.extra?.firebaseStorageBucket,
  messagingSenderId: Constants?.manifest?.extra?.firebaseMessagingSenderId,
  appId: Constants?.manifest?.extra?.firebaseAppId
};
const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);

const functions = getFunctions(firebaseApp);
//connectFunctionsEmulator(functions,"127.0.0.1", 5001)

export {auth, firestore, functions};