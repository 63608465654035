import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native';

export const Pensando = () => {
  const [loadingText, setLoadingText] = useState('Pensando');

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (loadingText === 'Pensando...') {
        setLoadingText('Pensando');
      } else if (loadingText === 'Pensando..') {
        setLoadingText('Pensando...');
      } else {
        setLoadingText('Pensando..');
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [loadingText]);

  return (
    <View style={styles.container}>
      <ActivityIndicator size="large" color="red" />
      <Text style={styles.text}>{loadingText}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 20,
    color:'white',
    fontWeight: 'bold',
    marginTop: 10,
  },
});