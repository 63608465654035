import React, { useContext, useEffect, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { View, ActivityIndicator } from 'react-native';

import { AppContext } from '../context/AppContextProvider';
import { Background } from '../components/Background';

import { NavigationDrawer } from './NavigationDrawer';

export const RootNavigator = () => {
  // @ts-ignore
  const { user, isLoading } = useContext(AppContext);
  
  let content = <></>;

  if (isLoading) {
    content = (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator size='large' />
      </View>
    );
  }
  else{
    content = (<NavigationContainer>
      {user ? <NavigationDrawer user={user} /> : <NavigationDrawer user={undefined} />}
    </NavigationContainer>)
  }

  return (
    <Background>
      {content}
    </Background>
  );
}