import 'react-native-gesture-handler';
import React from 'react';
import { createDrawerNavigator, DrawerItemList, DrawerItem, DrawerContentScrollView } from '@react-navigation/drawer';

import WelcomeScreen from '../screens/WelcomeScreen';
import LoginScreen from '../screens/LoginScreen';

import AdminDashboardScreen from '../screens/admin/AdminDashboardScreen';
import AdminUsuariosScreen from '../screens/admin/AdminUsuariosScreen';
import AdminPerfilScreen from '../screens/admin/AdminPerfilScreen';

import ProveedorDashboardScreen from '../screens/proveedor/ProveedorDashboardScreen';
import ProveedorOrdenesBorradorScreen from '../screens/proveedor/ProveedorOrdenesBorradorScreen';
import ProveedorOrdenesCotizacionScreen from '../screens/proveedor/ProveedorOrdenesCotizacionScreen';
import ProveedorOrdenesDesarrolloScreen from '../screens/proveedor/ProveedorOrdenesDesarrolloScreen';
import ProveedorOrdenesFacturacionScreen from '../screens/proveedor/ProveedorOrdenesFacturacionScreen';
import ProveedorPerfilScreen from '../screens/proveedor/ProveedorPerfilScreen';

import ClienteDashboardScreen from '../screens/cliente/ClienteDashboardScreen';
import ClienteOrdenesBorradorScreen from '../screens/cliente/ClienteOrdenesBorradorScreen';
import ClienteOrdenesCotizacionScreen from '../screens/cliente/ClienteOrdenesCotizacionScreen';
import ClienteOrdenesDesarrolloScreen from '../screens/cliente/ClienteOrdenesDesarrolloScreen';
import ClienteOrdenesFacturacionScreen from '../screens/cliente/ClienteOrdenesFacturacionScreen';
import ClientePerfilScreen from '../screens/cliente/ClientePerfilScreen';
import { TouchableOpacity, useWindowDimensions } from 'react-native';

/*const CustomDrawerContent = (props) => {
  return (
    <DrawerContentScrollView {...props}>
      <DrawerItemList {...props} />
      <DrawerItem
        label="Close drawer"
        onPress={() => props.navigation.closeDrawer()}
      />
      <DrawerItem
        label="Toggle drawer"
        onPress={() => props.navigation.toggleDrawer()}
      />
    </DrawerContentScrollView>
  );
   <Drawer.Navigator initialRouteName="Inicio" screenOptions={{ drawerPosition: 'right' }} useLegacyImplementation>
        <Drawer.Screen name="Inicio" component={WelcomeScreen} options={{headerShown:false }}  />
}*/

const Drawer = createDrawerNavigator();


export const NavigationDrawer = (user)=> {
  const dimensions = useWindowDimensions();
  const isLargeScreen = dimensions.width >= 768;
  if(!user.user){
    return (
      <Drawer.Navigator initialRouteName="Asistente Tecnico" useLegacyImplementation>
        <Drawer.Screen name="Asistente Tecnico" options={{ headerShown:false }} component={WelcomeScreen} />
        <Drawer.Screen name='Login' options={{ headerShown:false }} component={LoginScreen} />
      </Drawer.Navigator>
    );
  }

  const generateMenu=(roles)=>{
    const drawerScreens = [];
    roles.map(role => {
      switch (role){
        case 'TECHMEX_ADMIN' :
          //<Drawer.Navigator  initialRouteName="Inicio" useLegacyImplementation drawerContent={(props) => <CustomDrawerContent {...props} />>
          drawerScreens.push(<Drawer.Screen key="AdminDashboard" name="AdminDashboard" options={{ drawerLabel: "Dashboard Admin"}} component={AdminDashboardScreen} />);
          drawerScreens.push(<Drawer.Screen key="AdminUsuarios" name="AdminUsuarios" options={{ drawerLabel: 'Usuarios'}} component={AdminUsuariosScreen} />);
          drawerScreens.push(<Drawer.Screen key="AdminPerfil" name="AdminPerfil" options={{ drawerLabel: "Perfil Admin"}} component={AdminPerfilScreen} />);
          break;
          case 'TECHMEX_PROVEEDOR' :
          //<Drawer.Navigator initialRouteName="Dashboard Proveedor" useLegacyImplementation>
            drawerScreens.push(<Drawer.Screen key="ProveedorDashboard" name="ProveedorDashboard" options={{ drawerLabel: "Dashboard Proveedor"}} component={ProveedorDashboardScreen} />);
            drawerScreens.push(<Drawer.Screen key="ProveedorOrdenesBorrador" name="ProveedorOrdenesBorrador" options={{ drawerLabel: "Ordenes en Borrador"}} component={ProveedorOrdenesBorradorScreen} />);
            drawerScreens.push(<Drawer.Screen key="ProveedorOrdenesCotizacion" name="ProveedorOrdenesCotizacion" options={{ drawerLabel: "Ordenes en Cotizacion"}} component={ProveedorOrdenesCotizacionScreen} />);
            drawerScreens.push(<Drawer.Screen key="ProveedorOrdenesDesarrollo" name="ProveedorOrdenesDesarrollo" options={{ drawerLabel: "Ordenes en Desarrollo"}} component={ProveedorOrdenesDesarrolloScreen} />);
            drawerScreens.push(<Drawer.Screen key="ProveedorOrdenesFacturacion" name="ProveedorOrdenesFacturacion" options={{ drawerLabel: "Ordenes en Facturacion"}} component={ProveedorOrdenesFacturacionScreen} />);
            drawerScreens.push(<Drawer.Screen key="ProveedorPerfil" name="ProveedorPerfil" options={{ drawerLabel: "Perfil Proveedor"}} component={ProveedorPerfilScreen} />);
          //</Drawer.Navigator>
          break;
          case 'TECHMEX_CLIENTE' :
            
              //<Drawer.Navigator initialRouteName="Dashboaard Cliente" useLegacyImplementation>
              drawerScreens.push(<Drawer.Screen key="ClienteDashboard" name="ClienteDashboard"  options={{ drawerLabel: "Dashboard Cliente"}} component={ClienteDashboardScreen} />)
              drawerScreens.push(<Drawer.Screen key="ClienteOrdenesBorrador" name="ClienteOrdenesBorrador" options={{ drawerLabel: "Ordenes en Borrador"}} component={ClienteOrdenesBorradorScreen} />)
              drawerScreens.push(<Drawer.Screen key="ClienteOrdenesCotizacion" name="ClienteOrdenesCotizacion" options={{ drawerLabel: "Ordenes en Cotizacion"}} component={ClienteOrdenesCotizacionScreen} />)
              drawerScreens.push(<Drawer.Screen key="ClienteOrdenesDesarrollo" name="ClienteOrdenesDesarrollo" options={{ drawerLabel: "Ordenes en Desarrollo"}} component={ClienteOrdenesDesarrolloScreen} />)
              drawerScreens.push(<Drawer.Screen key="ClienteOrdenesFacturacion" name="ClienteOrdenesFacturacion" options={{ drawerLabel: "Ordenes en Facturacion"}} component={ClienteOrdenesFacturacionScreen} />)
              drawerScreens.push(<Drawer.Screen key="ClientePerfil" name="ClientePerfil" options={{ drawerLabel: "Perfil Cliente"}} component={ClientePerfilScreen} />)
              //</Drawer.Navigator>
          break;
      }
    });

    return drawerScreens;
    
  }


  return (<Drawer.Navigator useLegacyImplementation>
    {generateMenu(user.user.roles)}
    </Drawer.Navigator>)
}