import { StatusBar } from 'expo-status-bar';
import React, { useContext } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Colors } from '../../utils/colors';


import { AppContext } from '../../context/AppContextProvider';

export default function ProveedorOrdenesDesarrolloScreen() {
  // @ts-ignore
  const { user } = useContext(AppContext);
  
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.title}>Pantalla de Pagos de {user.email}!</Text>       
      </View>
      <Text style={styles.text}>Tu UID es: {user.uid} </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.rentappGrey,
    paddingTop: 50,
    paddingHorizontal: 12
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24
  },
  title: {
    fontSize: 24,
    fontWeight: '600',
    color: '#fff'
  },
  text: {
    fontSize: 16,
    fontWeight: 'normal',
    color: '#fff'
  }
});
