import { StatusBar } from 'expo-status-bar';
import React, {useContext, useEffect, useState} from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Colors } from '../../utils/colors';


import { AppContext } from '../../context/AppContextProvider';
import {getOrdenes} from "../../api";

export default function HomeScreen() {
    // @ts-ignore
    const { user } = useContext(AppContext);
    // @ts-ignore
    //const [ ordenes, setOrdenes ] = useState([]);
    // @ts-ignore

    /*
    useEffect(async () => {
        const callGetOrdenes = async (uid) =>{
            const ordenes = await getOrdenes(uid);
            console.log(ordenes);
            return ordenes;

        }
        setOrdenes(await callGetOrdenes(user.uid));
        console.log(ordenes);
    },[])
    */
    return (
    <View style={styles.container}>
      <StatusBar />
      <View style={styles.row}>
        <Text style={styles.title}>Bienvenido {user.nombres}</Text>
      </View>
        <View style={styles.row}>
            <Text style={styles.text}>Este es el estado actual de tus contratos: </Text>
        </View>

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.rentappGrey,
    paddingTop: 50,
    paddingHorizontal: 12
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24
  },
  title: {
    fontSize: 24,
    fontWeight: '600',
    color: '#222'
  },
  text: {
    fontSize: 16,
    fontWeight: 'normal',
    color: '#777'
  }
});

/*import * as React from 'react';
import { Button, View } from 'react-native';

export default function HomeScreen({ navigation }) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Text >Home Screen {user.email}!</Text>
        <Button
          onPress={() => navigation.navigate('Notifications')}
          title="Go to notifications"
        />
      </View>
    );
  }*/