import { StyleSheet, TextInput, Text, View, Button } from 'react-native';

export const TechMexBrandName = () => {
    return (<Text style={styles.techmexText}>TechMex</Text>)
}

const styles = StyleSheet.create({
    techmexText:{
      fontSize: 50,
      color:'#fff'
    }
  });
