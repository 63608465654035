import { StatusBar } from 'expo-status-bar';
import React, { useContext } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Colors } from '../../utils/colors';


import { AppContext } from '../../context/AppContextProvider';

export default function ProveedorOrdenesCotizacionScreen() {
  // @ts-ignore
  const { user } = useContext(AppContext);
  //<StatusBar style='dark-content' />
  return (
    
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      
      <View style={styles.row}>
        <Text style={styles.title}>Pantalla de Documentacion de {user.email}!</Text>       
      </View>
      <Text style={styles.text}>Tu UID es: {user.uid} </Text>
      {/*<Button
            onPress={() => navigation.navigate('Inicio')}
            title="Ir a Inicio"
  />
        <Button onPress={() => navigation.goBack()} title="Go back home" />    */} 
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.rentappGrey,
    paddingTop: 50,
    paddingHorizontal: 12
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24
  },
  title: {
    fontSize: 24,
    fontWeight: '600',
    color: '#222'
  },
  text: {
    fontSize: 16,
    fontWeight: 'normal',
    color: '#222'
  }
});