import {  Canvas } from '@react-three/fiber'
import { StyleSheet, TextInput, Text, View, Button } from 'react-native';
import { Boxes} from './Boxes' 


export const TechMexCanvas =(qestion, answer) =>{ 
    return (<Canvas camera={{position:[0,6,8], rotation:[-.12,0,0]}} style={styles.canvas}>
    <ambientLight />
    <pointLight position={[10, 10, 10]} />
    <Boxes />
  </Canvas>)
}

const styles = StyleSheet.create({
    canvas:{
      height: '100%',
      maxHeight:420,
      backgroundColor: '#222',
    }    
  });
  

