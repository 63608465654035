import React from 'react'
import {  useFrame } from '@react-three/fiber'


export default function Box(props) {
    const mesh = React.useRef(null)
    const [hovered, setHover] = React.useState(false)
    const [active, setActive] = React.useState(false)
    useFrame((state, delta) => (mesh.current.rotation.y += 0.01))
    return (
      <mesh
        {...props}
        ref={mesh}
        scale={active ? 1.25 : 1}
        onClick={(event) => setActive(!active)}
        onPointerOver={(event) => setHover(true)}
        onPointerOut={(event) => setHover(false)}>
        <boxGeometry args={[.75, .75, .75]} />
        <meshStandardMaterial color={active ? 'dodgerblue' : 'red'} />
      </mesh>
    )
  }

  /* <meshStandardMaterial color={hovered ? 'green' : 'red'} />*/