import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { useState } from 'react';
import { Button, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import { auth } from "../config/firebase";
import {Colors} from '../utils/colors';
import {InputField} from '../components/InputField'
import {ErrorMessage} from '../components/ErrorMessage'
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import Icon from 'react-native-vector-icons/FontAwesome';


export default function LoginScreen({ navigation }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [rightIcon, setRightIcon] = useState('eye');
  const [loginError, setLoginError] = useState('');

  const handlePasswordVisibility = () => {
    if (rightIcon === 'eye') {
      setRightIcon('eye-off');
      setPasswordVisibility(!passwordVisibility);
    } else if (rightIcon === 'eye-off') {
      setRightIcon('eye');
      setPasswordVisibility(!passwordVisibility);
    }
  };

  const onLogin = async () => {
    try {
      if (email !== '' && password !== '') {
        await signInWithEmailAndPassword(auth, email, password);
      }
    } catch (error) {
      setLoginError(error.message);
    }
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
            style={
                styles.floatingButton
                }
            onPress={()=>{navigation.toggleDrawer();}}
            >
            <Icon
                name="bars"
                size={25}
                style={styles.buttonIcon}
            />
        </TouchableOpacity>    
      <Text style={styles.title}>Acceso</Text>
      <InputField
        inputStyle={{
          fontSize: 14
        }}
        containerStyle={{
          backgroundColor: '#fff',
          marginBottom: 20
        }}
        leftIcon='email'
        placeholder='Proporciona tu email'
        autoCapitalize='none'
        keyboardType='email-address'
        textContentType='emailAddress'
        autoFocus={true}
        value={email}
        onChangeText={text => setEmail(text)} rightIcon={undefined} handlePasswordVisibility={undefined}      />
      <InputField
        inputStyle={{
          fontSize: 14
        }}
        containerStyle={{
          backgroundColor: '#fff',
          marginBottom: 20
        }}
        leftIcon='lock'
        placeholder='Proporciona tu password'
        autoCapitalize='none'
        autoCorrect={false}
        secureTextEntry={passwordVisibility}
        textContentType='password'
        rightIcon={rightIcon}
        value={password}
        onChangeText={text => setPassword(text)}
        handlePasswordVisibility={handlePasswordVisibility}
      />
      {loginError ? <ErrorMessage error={loginError} visible={true} /> : null}
        <View>
            <Button
                color="#ff5733"
                onPress={onLogin}
                title='Acceso'
            />
        </View>
        <View>
            <Button
              color="#ff5733"
              onPress={() => navigation.navigate('ForgotPassword')}
              title='Olvidaste tu Password?'             
            />
        </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { 
    height: '100%', 
    padding: 5,
    backgroundColor:'#222222',
    flex:1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonIcon: {        
    color: '#FFF',        
  },
  floatingButton:{
    position: 'absolute',
    top: 30,
    right: 30,
    zIndex:1
  },
  title: {
    fontSize: 24,
    fontWeight: '600',
    color: '#fff',
    alignSelf: 'center',
    paddingBottom: 24
  }
});