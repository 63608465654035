import React from 'react';

import { AppContextProvider } from '../context/AppContextProvider';
import {RootNavigator} from './RootNavigator';

/**
 * Wrap all providers here
 */

export default function Routes() {
  return (
    <AppContextProvider>
      <RootNavigator />
    </AppContextProvider>
  );
}