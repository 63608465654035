import React from 'react';
import { View, StyleSheet} from 'react-native';
import { Colors} from '../utils/colors';

export const Background = ({children})=>{
    return(    
        <View style={styles.container}>
            {children}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        height: '100%', 
        padding: 0,
        backgroundColor: Colors.black,
        flex:1,
        flexDirection: 'column',
    },
    image:{
        flex:1,
        resizeMode:'cover'
    }
});
