import React, { useState, createContext, useEffect } from 'react';
import { auth, firestore, functions} from "../config/firebase";
import { GoogleAuthProvider, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithRedirect, signOut, UserCredential } from "firebase/auth";
import { collection, query, where, doc,setDoc, getDoc, getDocs } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";


export const AppContext = createContext({});

export const AppContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  //const [discussions, setDiscussions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);


  const signIn= async (email,password)=>{
    return signInWithEmailAndPassword(auth,email,password)
  }

  const signUp= async (email,password)=>{
    const infoUser = createUserWithEmailAndPassword(auth,email,password);
    /* tslint:disable-next-line */
    const docuRef = doc(firestore, `users/${(await infoUser).user.uid}`);
    setDoc(docuRef, { name:name, email: email, password: password });
    return infoUser;
  }

  const logOut= async ()=> {
    return signOut(auth);
  }

  const generate=async(requestMessage)=>{
    const tRPCRequest = { 
      type: "TECHMEX_GENERATE", 
      requestData:{
        message:requestMessage
      } 
    } 
    const tRPC = httpsCallable(functions, 'tRPC');
    let responseMessage=''
    try{
      const tRPCResponse:any = await tRPC(tRPCRequest)
      console.log(JSON.stringify(tRPCResponse))
      responseMessage=tRPCResponse.data.responseData.message;
    }catch(e){
      responseMessage='Ha habido un error'
    }
    
    return responseMessage;

  }

  const getUserData = async (uid) => {
    const docuRef = doc(firestore, `usuarios/${uid}`);
    const docuBin = await getDoc(docuRef);
    return docuBin.data();
  }

  useEffect(() => {
    const setUserWithFirebaseAndRol = (userFirebase, setUser) => {
      getUserData(userFirebase.uid).then((userData) => {
        const user = {
          uid: userFirebase.uid,
          email: userFirebase.email,
          nombres: userData.nombres,
          apellidos: userData.apellidos,
          roles:userData.roles
        };
        setUser(user);
      });
    }

    // onAuthStateChanged returns an unsubscriber
    const unsubscribeAuth = onAuthStateChanged(auth, async authenticatedUser => {
      try {
        await (authenticatedUser ? setUserWithFirebaseAndRol(authenticatedUser, setUser) : setUser(null));
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    });

    // unsubscribe auth listener on unmount
    return unsubscribeAuth;
  }, []);

  const value={ 
    signIn,
    signUp,
    logOut,
    generate,
    user, 
    isLoading
  }

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};
