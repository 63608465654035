import { StatusBar } from 'expo-status-bar';
import React, { useContext } from 'react';
import {Button, StyleSheet, Text, View } from 'react-native';
import { Colors } from '../../utils/colors';


import { AppContext } from '../../context/AppContextProvider';
import { auth } from "../../config/firebase";

import { signOut, getAuth } from "firebase/auth";

export default function ClientePerfilScreen() {
  // @ts-ignore
  const { user } = useContext(AppContext);
  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.title}>Pantalla de Perfil de {user.email}!</Text>
      </View>
        <View style={styles.row}>
        <Text style={styles.text}>Tu UID es: {user.uid} </Text>
        </View>
        <View style={styles.row}>
            <Button
            onPress={handleSignOut}
            title='Cerrar Sesión'
            />
        </View>

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.rentappGrey,
    paddingTop: 50,
    paddingHorizontal: 12
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24
  },
  title: {
    fontSize: 24,
    fontWeight: '600',
    color: '#fff'
  },
  text: {
    fontSize: 16,
    fontWeight: 'normal',
    color: '#fff'
  }
});

/*import * as React from 'react';
import { Button, View } from 'react-native';

export default function HomeScreen({ navigation }) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Text >Home Screen {user.email}!</Text>
        <Button
          onPress={() => navigation.navigate('Notifications')}
          title="Go to notifications"
        />
      </View>
    );
  }*/